import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Mystery has been swirling all over LA and the inhabitants are scared. Ten murders in ten weeks, all of which were beautiful women, and still, nobody has a clue who the spiteful killer is. The victims were all suffocated, and the cold blood monster left nothing but a pair of black velvet gloves.`}</p>
    <p>{`Shane Connor is a passionate and admirable actor with a fondness for music. He doesn't know it yet but he is the only one who will look into the shameless killer’s eyes.`}</p>
    <p>{`One week before the first murder, Shane got a proposition of a role. It was a film that had a far similar plot. With a cold-blooded killer so passionate for women, willing to kill them so that no one except for him will get the pleasure of being with them. Freaky coincidence the actor thought. He knew it was the role of his life, as after mystery became so famous that people were whispering about it in farther parts of the world. It would bring him such fame love, everything he would always want. It was even predicted to win an oscar. Shane was given the role of the mystery murderer, which he was deeply glad to receive.`}</p>
    <p>{`Shane was practicing for days and nights. He would spend hours staring in the kitchen mirror, trying to find some common inner trains with the murderer. Thinking what he thought, why would he do it, and how he would act in peaceful daylight. But once he caught himself somehow knowing the murders were all committed at night. How could he know such a detail? Shane blamed it on accidentally hearing it from one of those gossiping whispers.`}</p>
    <p>{`I the further few weeks strange things had happened to him. Once he had woken up with a broken finger even, however, Shane kept it all to himself. Meanwhile, Shane's acting skills got better and better, and the producers were amazed at how passionately each of his muscles was moving. The director, Allison Parker even said; "You must be no one else but the murderer himself".`}</p>
    <p>{`The next morning Alison was not there. Where was she thought Shane remembering the last time they had spoken as he thought. That's when Shane realized everything he had done.`}</p>
    <p>{`That night Shane had put those black velvet gloves on and that night and felt even more passionate.`}<br parentName="p"></br>{`
`}{`He walked over to the window and reflected on his glamorous surroundings. He had always loved relaxing LA Casino with its small, grey casino chips. It was a place that encouraged his tendency to feel passionate.`}</p>
    <p>{`Then he saw something in the distance, or rather someone. It was the figure of Alison Parker. Alison waved at him with a shy smile. Shane gulped. He glanced at his own reflection. He was an eager, selfish, whiskey drinker who was uncontrollable that night. That night he could not stop looking at Alison’s red dress. The rain hammered like all the 10 lost heartbeats of helpless victims of his, making Shane blinded.`}</p>
    <p>{`As Alison stepped outside and Shane came closer, Alison spotted the strange glint in Shane’s eye.`}</p>
    <p>{`"You wanna go for a walk, I know a good place not far from here", mumbled Shane.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      